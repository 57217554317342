.inline-block {
  max-width: 100%;
  display: inline-block
}

.unlogged-header {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
