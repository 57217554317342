.lost-page {
  display: -ms-grid;
  display: grid;
  padding: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--body-bg-color);

  @media screen and (max-width: 479px) {
    display: block;
    border: none;
    padding: 0;
    overflow: auto;
  }
}

.message-area {
  display: flex;
  flex-direction: column;
  padding: 48px;
  background-color: var(--component-bg-color);
  color: var(--component-text-color);
}

.links-area {
  display: flex;
  padding: 48px;
  justify-content: center;

  .menu-list {
    display: flex;
    flex-direction: column;
    padding: 0px;
    justify-content: center;

    > div {
      margin-bottom: 48px;
    }
  }
}

.main-content {
  @media screen and (max-width: 479px) {
    margin: 48px 0 0;
  }
}
